
import { useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
export const useSlackWebhook = () => {
    const [{ courseId, tutorInfo }] = useStore(StoreTypes.course);
    const [{ role, name, userId }] = useStore(StoreTypes.user);

    const sendSlackMeetingIssue = useCallback(async (message) => {

        if (tutorInfo == null) {
            console.error("turtor data not found");
            return;
        }

        let startTime = new Date(tutorInfo.startTime);
        let endTime = new Date(tutorInfo.endTime);
        let preUserId = userId.slice(0, userId.indexOf('_'));
        let roleName = getRoleName(role);
        let students = tutorInfo.students.map((student) => {
            let studentId = student.studentId.slice(0, student.studentId.indexOf('_'));
            return `${studentId}(${student.studentNickname})`;
        });

        const msgData = {
            "text": `
課程ID：${courseId}
課程日期：${startTime.getMonth() + 1}/${startTime.getDate()}/${startTime.getFullYear()}
課程時間：${startTime.toLocaleTimeString('it-IT')}-${endTime.toLocaleTimeString('it-IT')}
授課教師：${tutorInfo.tutorNickname}(${tutorInfo.tutorId})
學生：${students.join(",")}
班級名稱：${tutorInfo.info.className}
障礙狀況：${roleName} ${name}(${preUserId}) ${message}
UserAgent：${window.navigator.userAgent}`
        };

        await fetch(process.env.REACT_APP_SLACK_WEBHOOK_URL, {
            method: 'POST',
            body: JSON.stringify(msgData),
        }).then(function (response) {
            if (!response.ok) {
                console.log('Send webhook response error.');
            }
        }).catch(function (error) {
            console.log('Send webhook error: ', error.message);
        });;

    }, [courseId, tutorInfo]);


    const getRoleName = (role) => {
        if (role == 'TUTOR_USER')
            return '學生';
        else if (role == 'TUTOR')
            return '授課教師';
        return role;
    };

    return { sendSlackMeetingIssue };
};

