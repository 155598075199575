export const CANVAS_INITIALIZE = 'CANVAS_INITIALIZE';
export const CANVAS_RESIZE = 'CANVAS_RESIZE';
export const CANVAS_ACTIVATE = 'CANVAS_ACTIVATE';
export const CANVAS_INACTIVATE = 'CANVAS_INACTIVATE';
export const CANVAS_REFRESH = 'CANVAS_REFRESH';
export const CANVAS_CHANGE_PAINTER_MODE = 'CANVAS_CHANGE_PAINTER_MODE';
export const CANVAS_OBJECT_MODE = 'CANVAS_OBJECT_MODE';
export const CANVAS_DRAWING_BRUSH_COLOR = 'CANVAS_DRAWING_BRUSH_COLOR';
export const CANVAS_DRAWING_BRUSH_LINE_WIDTH =
  'CANVAS_DRAWING_BRUSH_LINE_WIDTH';
export const CANVAS_DRAWING_BRUSH = 'CANVAS_DRAWING_BRUSH';
export const CANVAS_ERASE_OBJECT = 'CANVAS_ERASE_OBJECT';
export const CANVAS_EXPORT_SVG = 'CANVAS_EXPORT_SVG';
export const CANVAS_IMPORT_SVG = 'CANVAS_IMPORT_SVG';
export const CANVAS_RESTORE_FROM_DB = 'CANVAS_RESTORE_FROM_DB';
export const SAVE_CANVAS_JSON_TIME = 'SAVE_CANVAS_JSON_TIME';
export const CANVAS_RESET_SVG = 'CANVAS_RESET_SVG';
export const CANVAS_ERASE_ALL = 'CANVAS_ERASE_ALL';
export const CANVAS_CHANGE_SVG_OBJECT_PANEL = 'CANVAS_CHANGE_SVG_OBJECT_PANEL';
export const CANVAS_CHANGE_STAMP_STATUS = 'CANVAS_CHANGE_STAMP_STATUS';
export const CANVAS_TURN_ON_EXTRA_FIELDS_FOR_EDITOR =
  'CANVAS_TURN_ON_EXTRA_FIELDS_FOR_EDITOR';
export const SET_CANVAS_ACTIVE_OBJECT = 'SET_CANVAS_ACTIVE_OBJECT';
export const SET_CANVAS_OBJECT_PROPERTY = 'SET_CANVAS_OBJECT_PROPERTY';
export const SET_CANVAS_PAINTER_TOOL = 'SET_CANVAS_PAINTER_TOOL';
export const SET_CANVAS_SHOW = 'SET_CANVAS_SHOW';
export const SET_SVG_SHOW = 'SET_SVG_SHOW';
export const SET_CANVAS_SVG_ELEMENT = 'SET_CANVAS_SVG_ELEMENT';

export const CANVAS_CHANGE_COLOR = 'CANVAS_CHANGE_COLOR';
export const PICK_COLOR_CLOSE = 'PICK_COLOR_CLOSE';
export const CHANGE_LINE_WIDTH = 'CHANGE_LINE_WIDTH';
export const CHANGE_DRAWING_BRUSH = 'CHANGE_DRAWING_BRUSH';

export const CANVAS_CHANGE_LINE_TYPE = 'CANVAS_CHANGE_LINE_TYPE';
export const CANVAS_CHANGE_SHAPE_FILL_TYPE = 'CANVAS_CHANGE_SHAPE_FILL_TYPE';
export const CANVAS_CHANGE_PAINTER_TYPE = 'CANVAS_CHANGE_PAINTER_TYPE';
export const ADD_OBJECT = 'ADD_OBJECT';
export const TO_DATA_URL = 'TO_DATA_URL';

export const OBJECT_SELECTED = 'OBJECT_SELECTED';
export const OBJECT_MOVING = 'OBJECT_MOVING';
export const OBJECT_MODIFIED = 'OBJECT_MODIFIED';
export const OBJECT_SCALING = 'OBJECT_SCALING';
export const OBJECT_CLEARED = 'OBJECT_CLEARED';

export const OBJECT_SET_LEFT = 'OBJECT_SET_LEFT';
export const OBJECT_SET_TOP = 'OBJECT_SET_TOP';
export const OBJECT_SET_WIDTH = 'OBJECT_SET_WIDTH';
export const OBJECT_SET_HEIGHT = 'OBJECT_SET_HEIGHT';
export const OBJECT_SET_FONT_FAMILY = 'OBJECT_SET_FONT_FAMILY';
export const OBJECT_SET_FONT_SIZE = 'OBJECT_SET_FONT_SIZE';
export const OBJECT_SET_LINE_WIDTH = 'OBJECT_SET_LINE_WIDTH';

export const FONTS_INITIALIZE = 'FONTS_INITIALIZE';
export const FONTS_ACTIVE = 'FONTS_ACTIVE';

export const KEYBOARD_DELETE = 'KEYBOARD_DELETE';
export const KEYBOARD_BACKSPACE = 'KEYBOARD_BACKSPACE';
export const KEYBOARD_PRESSED = 'KEYBOARD_PRESSED';

export const SET_BOOKS = 'SET_BOOKS';
export const SET_BOOK_ID = 'SET_BOOK_ID';
export const SET_BOOK_STYLES = 'SET_BOOK_STYLES';
export const SET_BOOK_CATALOG = 'SET_BOOK_CATALOG';
export const SET_BOOK_CONTENT = 'SET_BOOK_CONTENT';
export const SET_INTERACTIVE_OBJECTS = 'SET_INTERACTIVE_OBJECTS';
export const SET_INTERACTIVE_OBJECTS_SVG = 'SET_INTERACTIVE_OBJECTS_SVG';
export const RESET_BOOK_CONTENT = 'RESET_BOOK_CONTENT';
export const SET_BOOK_ORIENTATION = 'SET_BOOK_ORIENTATION';
export const TOGGLE_DRAW_AREA = 'TOGGLE_DRAW_AREA';
export const SET_STICKY_NOTE_INFO = 'SET_STICKY_NOTE_INFO';
export const SET_CANVAS_SVG_OBJECT_ID = 'SET_CANVAS_SVG_OBJECT_ID';

export const SET_READER_TOOL_HEIGHT = 'SET_READER_TOOL_HEIGHT';
export const SET_READER_TOOL = 'SET_READER_TOOL';
export const SWITCH_BOOK_PAGES_SHOW = 'SWITCH_BOOK_PAGES_SHOW';
export const SET_MARK_MODE = 'SET_MARK_MODE';
export const SET_MARK_TOOLS_SHOW = 'SET_MARK_TOOLS_SHOW';
export const SET_BOOK_PAGE_INDEX = 'SET_BOOK_PAGE_INDEX';
export const SET_SECTIONS_INDEX = 'SET_SECTIONS_INDEX';
export const TOGGLE_RIGHT_BAR = 'TOGGLE_RIGHT_BAR';
export const TOGGLE_LIVE_STREAM_MASTER_PANEL = 'TOGGLE_LIVE_STREAM_MASTER_PANEL';
export const TOGGLE_LIVE_STREAM_VIEWER_PANEL = 'TOGGLE_LIVE_STREAM_VIEWER_PANEL';
export const TOGGLE_LEFT_BAR = 'TOGGLE_LEFT_BAR';
export const TOGGLE_BOOKMARK = 'TOGGLE_BOOKMARK';
export const TOGGLE_PAGE_SEARCHER = 'TOGGLE_PAGE_SEARCHER';
export const SET_INDICATOR_INFO = 'SET_INDICATOR_INFO';
export const SET_SCALE_INFO = 'SET_SCALE_INFO';
export const SET_FULL_WIDTH_INFO = 'SET_FULL_WIDTH_INFO';
export const SET_READER_ZOOM_TYPE = 'SET_READER_ZOOM_TYPE';
export const SET_OBJECT_POINTER_EVENT_STATUS = 'SET_OBJECT_POINTER_EVENT_STATUS';
export const SET_SUBMENU_INFO = 'SET_SUBMENU_INFO';

export const WINDOW_RESIZE = 'WINDOW_RESIZE';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_MODAL_COMPONENTS = 'SET_MODAL_COMPONENTS';
export const SET_AREA_ZOOM_INTERACTIVE_OBJECTS = 'SET_AREA_ZOOM_INTERACTIVE_OBJECTS';
export const SET_PROGRESS = 'SET_PROGRESS';
export const SET_EXPRESSION_TYPE = 'SET_EXPRESSION_TYPE';
export const SET_TOOLS_IS_CONTROL = 'SET_TOOLS_IS_CONTROL';

// music modal
export const OPEN_MUSIC_MODAL = 'OPEN_MUSIC_MODAL';
export const CLOSE_MUSIC_MODAL = 'CLOSE_MUSIC_MODAL';
export const SET_MUSIC_MODAL_PROPERTY = 'SET_MUSIC_MODAL_PROPERTY';

// sideToolReducer
export const SET_SIDE_TOOL_CONTENT = 'SET_SIDE_TOOL_CONTENT';
export const SET_TEXT_SIDE_TOOL_SHOW = 'SET_TEXT_SIDE_TOOL_SHOW';

//userReducer
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const RESET_USER_INFO = 'RESET_USER_INFO';

// ReaderTool
export const CHANGE_READER_TOOL_STYLE = 'CHANGE_READER_TOOL_STYLE';
export const CHANGE_READER_TOOL_DIRECTION = 'CHANGE_READER_TOOL_DIRECTION';
export const SET_READER_TOOL_TYPE = 'SET_READER_TOOL_TYPE';
export const SET_MATH_TOOLS_SHOW = 'SET_MATH_TOOLS_SHOW';
export const SET_TOOLBOX_SHOW = 'SET_TOOLBOX_SHOW';
export const SET_READER_TOOLS = 'SET_READER_TOOLS';

// AnnotationReducer
export const UPDATE_ANNOTATION_INFO = 'UPDATE_ANNOTATION_INFO';
export const RESET_ANNOTATION_INFO = 'RESET_ANNOTATION_INFO';
export const SET_MARK_OBJECT = 'SET_MARK_OBJECT';
export const ADD_MARK_OBJECTS = 'ADD_MARK_OBJECTS';
export const UPDATE_MARK_OBJECTS = 'UPDATE_MARK_OBJECTS';

//// AnnotationReducer - activityInfo related
export const SET_FIRESTORE = 'SET_FIRESTORE';
export const UPDATE_ACTIVITY_INFO = 'UPDATE_ACTIVITY_INFO';
export const SET_SEATS = 'SET_SEATS';
export const TOGGLE_ACTIVITY_PANEL = 'TOGGLE_ACTIVITY_PANEL';

//// ExtendedReducer
export const SET_EXTENDED_CONTENT_FONT_SIZE = 'SET_EXTENDED_CONTENT_FONT_SIZE';
export const SET_EXTENDED_CONTENT_SHOW_ANSWERS = 'SET_EXTENDED_CONTENT_SHOW_ANSWERS';
export const SET_EXTENDED_CONTENT_SYNOPSIS_LIST = 'SET_EXTENDED_CONTENT_SYNOPSIS_LIST';
export const SET_EXTENDED_CONTENT_SYNOPSIS_TAB = 'SET_EXTENDED_CONTENT_SYNOPSIS_TAB';
export const CHANGE_EXTENDED_CONTENT_SCALE = 'CHANGE_EXTENDED_CONTENT_SCALE';
export const IMPORT_EXTENDED_CONTENT_CANVAS_SVG = 'IMPORT_EXTENDED_CONTENT_CANVAS_SVG';
export const SET_EXTENDED_CONTENT_WORD_LEARNING_GRID_PLAYBACK_RATE = 'SET_EXTENDED_CONTENT_WORD_LEARNING_GRID_PLAYBACK_RATE';
export const SET_EXTENDED_CONTENT_READER_TOOL_TYPE = 'SET_EXTENDED_CONTENT_READER_TOOL_TYPE';

//// UserSettingsReducer
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';


//// CourseReducer
export const SET_COURSE_INFO = 'SET_COURSE_INFO';
export const SET_REMOTE_ZOOM_INFO = 'SET_REMOTE_ZOOM_INFO';
export const SET_COURSE_FIRESTORE = 'SET_COURSE_FIRESTORE';
export const SET_CHIME_ROLE = 'SET_CHIME_ROLE';
export const SET_REWARD_INFO = 'SET_REWARD_INFO';
export const INCREASE_REWARD = 'INCREASE_REWARD';
export const SET_IS_FULL_VIDEO = 'SET_IS_FULL_VIDEO';
export const SET_ADMIN_FULL_SCREEN_TARGET = 'SET_ADMIN_FULL_SCREEN_TARGET';
export const SET_FINISHED_BEFORE = 'SET_FINISHED_BEFORE';
export const SHOW_FINISHED_BUTTON = 'SHOW_FINISHED_BUTTON';


//// wordGridReducer
export const SET_RADICAL_PROGRESS_MODAL_SHOW = 'SET_RADICAL_PROGRESS_MODAL_SHOW';
export const SET_RADICAL_CONTENT_SHOW = 'SET_RADICAL_CONTENT_SHOW';
export const SET_SUBCONTENT_SHOW = 'SET_SUBCONTENT_SHOW';
export const SET_EXTENSION_DATA = 'SET_EXTENSION_DATA';
export const SET_RELATED_WORDS = 'SET_RELATED_WORDS';
export const SET_CHANGE_TAB_INDEX = 'SET_CHANGE_TAB_INDEX';
export const SET_WORDS_VALUE = 'SET_WORDS_VALUE';
export const SET_WORDS_DATA = 'SET_WORDS_DATA';