import { useEnhanceReducer } from 'customHooks/enhanceReducer';
import * as types from 'constants/actionTypes';
import { EmojiTypes } from 'constants/expressionTypes';

export const initState = {
  courseId: '',
  timeSpanId: '',
  startAt: '',
  endAt: '',
  finishedBefore: null,
  showfinishedButton: null,
  organizationId: null,
  remoteZoomInfo: null,
  firestore: null,
  chimeRole: '',
  rewardInfo: { fireworks: 0, good: 0, like: 0, trophy: 0 },
  isFullVideo: false,
  fullScreenTarget: '',
  tutorInfo: null
};

const courseReducer = (state, action) => {
  switch (action.type) {
    case types.SET_COURSE_INFO:
      const { id, timeSpanId, startAt, endAt, organizationId, tutorInfo } = action.courseInfo;
      return { ...state, courseId: id, timeSpanId, startAt, endAt, organizationId, tutorInfo };
    case types.SET_REMOTE_ZOOM_INFO:
      return { ...state, remoteZoomInfo: action.remoteZoomInfo };
    case types.SET_COURSE_FIRESTORE:
      return { ...state, firestore: action.firestore };
    case types.SET_CHIME_ROLE:
      return { ...state, chimeRole: action.chimeRole };
    case types.SET_REWARD_INFO:
      return { ...state, rewardInfo: action.rewardInfo }
    case types.INCREASE_REWARD:
      const { expressionType } = action;
      const emojiType = EmojiTypes[expressionType];
      const rewardInfo  = {
        ...state.rewardInfo,
        [emojiType]: state.rewardInfo[emojiType] + 1
      }
      return { ...state, rewardInfo };
    case types.SET_IS_FULL_VIDEO:
      return { ...state, isFullVideo: action.isFullVideo };
    case types.SET_ADMIN_FULL_SCREEN_TARGET:
      return { ...state, fullScreenTarget: action.fullScreenTarget };
    case types.SET_FINISHED_BEFORE:
      return { ...state, finishedBefore: action.finishedBefore };
    case types.SHOW_FINISHED_BUTTON:
      return { ...state, showfinishedButton: action.showfinishedButton };
    default:
      return state;
  }
};

const useCourseReducer = () => useEnhanceReducer(courseReducer, initState);

export default useCourseReducer;
